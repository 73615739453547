import React from "react"

import colors from "../colors"

import { LandingLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import PersonList from "../components/person/PersonList"
import Map from "../components/map"

import { PositiveElection } from "../components/survey"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

const iconProps = {
  size: "60px",
  color: colors.darkPurple,
}

const LandingHabershamMedicalCenter = () => {
  return (
    <LandingLayout>
      <SEO
        title="The Long Term Care Insurance Consultation with Corey Rieck"
        ogDescription="We have arranged for you to set up a free, no-obligation LTC Consult with Corey Rieck. Corey will confidentially and personally evaluate your family’s specific needs"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
          <h1>
          Thank you for visiting from Habersham Medical Center.
          </h1>
          <p>
            While many of you understand what’s considered long term care, what most people don’t understand is how the benefit works. But you need to know how much coverage can be available if long term care becomes necessary for you personally or for your spouses/significant others.
          </p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/landing/habersham-medical-center.png"
              alt="Diverse Power"
            />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
          <p>
            This new benefit is what’s considered “guaranteed issue” which means there is no physical exam or no underwriting questions for you as an employee. There may be limited underwriting for spouses/significant others or other family members.
          </p>
          <p>
            Long term care planning is important to protecting your overall personal financial planning. Habersham Medical Center is partnering with Corey Rieck, MBA, CLTC, who is an expert at helping people plan for long term care expenses to avoid sacrificing their financial plans and family relationships.
          </p>
          <p>
            Corey became focused on long term care planning after managing his parents’ finances as they aged and needed long term care. He realized there had to be a better way for people to receive the best possible long term care without forfeiting assets and depleting their bank accounts.
          </p>
          <p>
          Corey knows how to help protect your financial assets and wealth management with a funding strategy for long term care. He can answer your questions and explain why you should not rely upon government programs or only your family members for long term care.
          </p>
          
          <h2>Start the Enrollment</h2>
          
          <PositiveElection companyName="Habersham Medical" />
          
          <h2>Complete the Enrollment</h2>
          
          <p>
          Visit <a href="https://ABEnroll.as.me/HabershamMedical" target="_blank">ABEnroll.as.me/HabershamMedical</a> or call <a href="tel:8005709064">(800) 570-9064</a> to start the enrollment process which should take less than 15 minutes.
          </p>

          <h2>Request A Meeting or Phone Call with Corey</h2>
          
          <p>
          Click the button below to schedule your free, no-obligation LTC Consult with Corey Rieck of The Long Term Care Planning Group (a $99.95 value). Or you can call <a href="tel:6788145088">(678) 814-5088</a> to schedule a meeting over the phone. Your conversation with him along with any personal information you share with him as part of the application process is strictly confidential.
          </p>

          <p>
           <ScheduleVisitButton />
          </p>

          <h2>Sign Up for Our Free Webinar</h2>
          <p>
          Sometimes the best way to learn more about a complex topic is to attend a short webinar to start with the basics. Even if you already have done long term care planning, you may still have unanswered questions about your coverage or plan. This webinar is about 20 minutes. Corey quickly introduces himself and then covers important details you need to know about long term care planning. You'll also receive a summary PDF overview for your review.
          </p>
          
          <div class="widget_wrapper"><div class="ewp_form_container ewp_widget_container" webinar_id="oaLCLjWqVbg9eimIr7VImQ==" ></div> <script type="text/javascript" src="https://ewpcdn.easywebinar.com/widget/js/ewp_widget.js?v=1.27.5" ></script></div>
          </div>
        }
      />
      <PageWrapper>
        <div
          style={{
            height: "1px",
            width: "100%",
            borderTop: "3px solid whitesmoke",
            display: "block",
            margin: "50px 0 50px 0",
          }}
        ></div>
        <PersonList shortnames={["corey-rieck"]} />
      </PageWrapper>
    </LandingLayout>
  )
}

export default LandingHabershamMedicalCenter
